<template>
  <b-overlay :show="loading" spinner-small rounded="sm">
    <b-card no-body>
      <div class="d-flex align-items-center justify-content-between p-2">
        <h5 class="mb-0">
          ประเทศทั้งหมด
        </h5>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="btn-icon rounded-circle"
          size="sm"
          @click="fetchUserLoginAllCountry"
        >
          <feather-icon icon="RotateCwIcon" size="14" />
        </b-button>
      </div>
      <div>
        <b-table responsive="sm" :items="listCountryData" :fields="fields" hover>
          <!-- <template #cell(index)="data">
            {{ data.index + 1 }}
          </template> -->

          <template #cell(country_code)="data">
            <div>
              <b-img :src="data.item.flag" :alt="`${data.value} country image`" class="mr-50" height="30" />
              <b-link :to="{ name: 'report-user-login-country-details', params: { code: data.value } }">
                {{ data.value }}
              </b-link>
            </div>
          </template>

          <template #cell(count)="data">
            <span>
              {{ gFormatNumberToCurrency(data.value) }}
            </span>
          </template>
        </b-table>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      listCountryData: [],
      fields: [
        { key: 'country_code', label: 'ประเทศ' },
        { key: 'count', label: 'จำนวนบัญชีทั้งหมด' },
      ],
    }
  },
  created() {
    this.fetchUserLoginAllCountry()
  },
  methods: {
    async fetchUserLoginAllCountry() {
      this.loading = true
      const resp = await this.api.getV2('api/admin/user-group-country').catch(() => null)
      // console.log('fetchUserLoginAllCountry', resp)
      this.loading = false

      if (resp?.code === 200) {
        this.listCountryData = [...resp.data]
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
