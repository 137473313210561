<template>
  <div>
    <h4 class="mb-2">สรุปรายงานการเข้าสู่ระบบ</h4>

    <b-row cols="1" cols-sm="2" cols-xl="3">
      <b-col v-for="provider in providerList" :key="`report-user-login-details-box-${provider.label}`">
        <b-card class="mb-50">
          <div class="d-flex align-items-center mb-1">
            <b-img :src="provider.iconPath" alt="browser img" class="mr-1" height="30" />

            <h5 class="mb-0 text-uppercase">{{ provider.label }}</h5>
          </div>
          <div class="d-flex align-item-center justify-content-between">
            <p class="mb-0">ทั้งหมด</p>
            <p class="mb-0">{{ gFormatNumberToCurrency(provider.total_count) }}</p>
          </div>
          <div class="d-flex align-item-center justify-content-between">
            <p class="mb-0">ล่าสุด 15 วัน</p>
            <p class="mb-0">{{ gFormatNumberToCurrency(provider.last_used_count) }}</p>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-card>
      <b-overlay :show="loading">
        <h5 class="mb-0 text-muted">ทั้งหมด</h5>
        <h3 class="mb-0">{{ gFormatNumberToCurrency(total.totalTotalCount || 0) }}</h3>
        <vue-apex-charts type="bar" height="350" :options="cChartOptions" :series="cChartSeries" />
      </b-overlay>
    </b-card>
    <b-card>
      <b-overlay :show="loading">
        <h5 class="mb-0 text-muted">ล่าสุด 15 วัน</h5>
        <h3 class="mb-0">{{ gFormatNumberToCurrency(total.totalLastUsedCount || 0) }}</h3>
        <vue-apex-charts type="bar" height="350" :options="cChartOptionsLast15d" :series="cChartSeriesLast15d" />
      </b-overlay>
    </b-card>

    <card-user-login-all-country />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import CardUserLoginAllCountry from './components/CardUserLoginAllCountry.vue'

export default {
  components: {
    VueApexCharts,
    CardUserLoginAllCountry,
  },
  data() {
    return {
      loading: false,
      total: {},
      providerList: [],
      budgetChartOptions: {
        chart: {
          type: 'bar',
          toolbar: {
            show: true,
          },
        },
        colors: [],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '60%',
            distributed: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
          textAnchor: 'start',
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
          },
          y: {
            formatter: value => this.gFormatNumberToCurrency(value),
            title: {
              formatter: () => 'จำนวน',
            },
          },
        },
      },
      providersColor: {
        apple: '#a6b1b7',
        facebook: '#3b5998',
        phone: '#4a154b',
        line: '#25d366',
        google: '#f35022',
        another: '#282828',
      },
      providersIcon: {
        // eslint-disable-next-line global-require
        apple: require('@/assets/images/icons/socials/apple.svg'),
        // eslint-disable-next-line global-require
        facebook: require('@/assets/images/icons/socials/fb.svg'),
        // eslint-disable-next-line global-require
        phone: require('@/assets/images/icons/socials/phone.svg'),
        // eslint-disable-next-line global-require
        line: require('@/assets/images/icons/socials/line.svg'),
        // eslint-disable-next-line global-require
        google: require('@/assets/images/icons/socials/google.svg'),
        // eslint-disable-next-line global-require
        another: require('@/assets/images/icons/socials/another.svg'),
      },
    }
  },
  computed: {
    cChartSeries() {
      const data = this.providerList.map(v => v.total_count).sort((a, b) => b - a)

      return [{ data }]
    },
    cChartOptions() {
      const sortDataList = [...this.providerList].sort((a, b) => b.total_count - a.total_count)
      const categories = sortDataList.map(v => v.label)
      const colors = sortDataList.map(v => v?.color || '#5d5fef')

      return {
        ...this.budgetChartOptions,
        colors,
        xaxis: {
          categories,
        },
      }
    },
    cChartSeriesLast15d() {
      const data = this.providerList.map(v => v.last_used_count).sort((a, b) => b - a)

      return [{ data }]
    },
    cChartOptionsLast15d() {
      const sortDataList = [...this.providerList].sort((a, b) => b.last_used_count - a.last_used_count)

      const categories = sortDataList.map(v => v.label)
      const colors = sortDataList.map(v => v?.color || '#5d5fef')

      return {
        ...this.budgetChartOptions,
        colors,
        xaxis: {
          categories,
        },
      }
    },
  },
  created() {
    this.fetchAllData()
  },
  methods: {
    async fetchAllData() {
      this.loading = true
      const resp = await this.api.getV2('api/admin/report/report-login')
      this.loading = false

      if (!resp) return
      if (resp?.code !== 200) {
        this.gDisplayToast('พบข้อผิดพลาด', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
        return
      }

      const all = resp.data[0]
      const providers = Object.keys(all).map(k => {
        const color = this.providersColor[k] ?? '#5d5fef'
        const iconPath = this.providersIcon[k] ?? ''

        return {
          label: k,
          ...all[k],
          color,
          iconPath,
        }
      })
      this.providerList = providers

      this.total = { ...resp.data?.total }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/chart-apex.scss';
</style>
